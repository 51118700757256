import React from "react";
import Cs2NyVideo from "../Cs2ErHer";
import Footer from "../Footer";

function Cs2Ankomst() {
  return (
    <>
      <Cs2NyVideo />
      <Footer />
    </>
  );
}

export default Cs2Ankomst;
