import React from "react";
import SpookyWinnerContent from "../SpookyContent";
import Footer from "../Footer";

function SpookyWinner() {
  return (
    <>
      <SpookyWinnerContent />
      <Footer />
    </>
  );
}

export default SpookyWinner;
