import React from "react";
import KokTeamNews from "../NewTeamComp";
import Footer from "../Footer";

function NyttLag() {
  return (
    <>
      <KokTeamNews />
      <Footer />
    </>
  );
}

export default NyttLag;
