import React from "react";
import JuleHilsen from "../JulehilsenComponent";
import Footer from "../Footer";

function GpsXmas() {
  return (
    <>
      <JuleHilsen />
      <Footer />
    </>
  );
}

export default GpsXmas;
